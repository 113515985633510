<template>
  <a-modal
    :title="title"
    width="90%"
    :visible="visible"
    :confirmLoading="loading"
    @ok="handleSubmit"
    @cancel="
      () => {
        this.visible = false
      }
    "
  >
    <a-spin :spinning="loading"></a-spin>
    <vxe-button @click="handleAdd(1)" class="mb-2">添加</vxe-button>
    <vxe-button @click="handleAdd(5)" class="mb-2">添加5行</vxe-button>
    <vxe-button @click="handleAdd(10)" class="mb-2">添加10行</vxe-button>
    <vxe-button @click="handleAdd(20)" class="mb-2">添加20行</vxe-button>
    <vxe-table border resizable :data="tableData" :edit-config="{ trigger: 'click', mode: 'cell' }">
      <vxe-table-column type="seq" width="60"></vxe-table-column>
      <vxe-table-column
        field="Name"
        title="名称"
        :edit-render="{ name: 'input', immediate: true, attrs: { type: 'text' } }"
      ></vxe-table-column>
      <vxe-table-column
        field="Number"
        title="数量"
        :edit-render="{ name: 'input', immediate: true, attrs: { type: 'text' } }"
      ></vxe-table-column>
      <vxe-table-column
        field="Remark"
        title="备注"
        :edit-render="{ name: 'input', immediate: true, attrs: { type: 'text' } }"
      ></vxe-table-column>
      <vxe-table-column
        field="Purpose"
        title="用途"
        :edit-render="{ name: 'input', immediate: true, attrs: { type: 'text' } }"
      ></vxe-table-column>
      <vxe-table-column title="型号与品牌" width="284">
        <template v-slot="{ row }">
          <a-button class="mb-2" size="small" type="primary" @click="handleBrandAdd(row.Brands)">增加</a-button>
          <div v-for="(brand, i) in row.Brands" :key="brand._Id" class="d-flex ai-center jc-center mb-2">
            <div>
              <vxe-input v-model="brand.BrandModel" placeholder="型号" size="medium" style="width: 100px"></vxe-input>
            </div>
            <a-divider type="vertical" />
            <div>
              <vxe-input v-model="brand.Brand" placeholder="品牌" size="medium" style="width: 100px"></vxe-input>
            </div>
            <a-button type="link" @click="handleBrandDelete(row.Brands, i)" size="small">删除</a-button>
          </div>
        </template>
      </vxe-table-column>
      <vxe-table-column title="操作" width="140">
        <template v-slot="{ rowIndex }">
          <vxe-button @click="handleDelete(rowIndex)" status="danger">删除</vxe-button>
          <vxe-button @click="handlesplice(rowIndex)">插入</vxe-button>
        </template>
      </vxe-table-column>
    </vxe-table>
  </a-modal>
</template>

<script>
export default {
  name: 'EditDetail',
  props: {
    parentObj: Object,
  },
  data() {
    return {
      visible: false,
      loading: false,
      title: '详细配置',
      tableData: [],
      id: null,
    }
  },
  methods: {
    openForm(id, _title) {
      this.visible = true
      this.id = id
      if (id) {
        this.loading = true
        this.$ajax(`/Main/Process/GetDetailSettings?processId=${id}`).then(resJson => {
          this.loading = false
          if (resJson.length) {
            resJson.forEach(row => {
              row.Brands.forEach(brand => (brand._Id = this.$$.newid()))
            })
            this.tableData = resJson
          } else {
            this.tableData = []
            this.handleAdd()
          }
        })
      }
    },
    handleSubmit() {
      this.loading = true
      this.tableData.forEach(row => {
        row.Brands = row.Brands.filter(brand => brand.BrandModel || brand.Brand)
      })
      this.$http
        .post('/Main/Process/SetDetailSettings', {
          ProcessId: this.id,
          DetailSettingRules: this.tableData,
        })
        .then(resJson => {
          this.loading = false

          if (resJson.Success) {
            this.$message.success('操作成功!')
            this.visible = false

            this.parentObj.getDataList()
          } else {
            this.$message.error(resJson.Msg)
          }
        })
    },
    handleDelete(rowIndex) {
      this.tableData.splice(rowIndex, 1)
    },
    onCellChange() {}, 
     handlesplice(index) {
      this.tableData.splice(index+1,0,{
        Id: '',
        Name: '',
        Purpose: '',
        Remark: '',
        Number: '',
        Brands: [{ BrandModel: '', Brand: '', _Id: this.$$.newid() }],
      })
    },
    handleAdd(num) {
      for (var i = 0; i < num; i++) {
        this.tableData.push({
          Id: '',
          Name: '',
          Purpose: '',
          Remark: '',
          Number: '',
          Brands: [{ BrandModel: '', Brand: '', _Id: this.$$.newid() }],
        })
     }
    },
    handleBrandAdd(brands) {
      brands.push({ BrandModel: '', Brand: '', _Id: this.$$.newid() })
    },
    handleBrandDelete(brands, i) {
      brands.splice(i, 1)
    },
  },
}
</script>
