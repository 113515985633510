<template>
  <a-card :bordered="false">
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="hanldleAdd()">新建</a-button>
      <a-button
        type="primary"
        icon="minus"
        @click="handleDelete(selectedRowKeys)"
        :disabled="!hasSelected()"
        :loading="loading"
      >
        删除
      </a-button>
      <a-button type="primary" icon="redo" @click="getDataList()">刷新</a-button>
    </div>

    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="10">
          <a-col :md="4" :sm="24">
            <a-form-item label="查询类别">
              <a-select allowClear v-model="queryParam.condition">
                <a-select-option key="Name">工序名称</a-select-option>
                <a-select-option key="SN">工序编号</a-select-option>
                <a-select-option key="Remarks">备注</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item>
              <a-input v-model="queryParam.keyword" placeholder="关键字" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-button
              type="primary"
              @click="
                () => {
                  this.pagination.current = 1
                  this.getDataList()
                }
              "
            >
              查询
            </a-button>
            <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table
      ref="table"
      :columns="columns"
      :rowKey="row => row.Id"
      :dataSource="data"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :bordered="true"
      size="small"
    >
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record.Id)">编辑</a>
        <template v-if="record.Id !== 'InspectionAll'">
          <a-divider type="vertical" />
          <a v-if="record.ProcessType == 0" @click="handleEditInspect(record.Id)">检验设定</a>
          <a v-if="record.ProcessType == 1" @click="handleEditDetail(record.Id)">详细配置</a>
          <a-divider type="vertical" />
          <a @click="handleCopy(record.Id)">复制</a>
          <a-divider type="vertical" />
          <a @click="handleDelete([record.Id])">删除</a>
        </template>
      </span>

      <span slot="processType" slot-scope="text, record">
        <template >
          <span v-if="record.ProcessType == 0">生产</span>
          <span v-if="(record.ProcessType == 1)">详细配置</span>
          <span v-if="(record.ProcessType == 2)">试模</span>
        </template>
      </span>
    </a-table>

    <edit-form ref="editForm" :parentObj="this"></edit-form>
    <edit-inspect ref="editInspect" :parentObj="this"></edit-inspect>
    <edit-detail ref="editDetail" :parentObj="this"></edit-detail>
  </a-card>
</template>

<script>
import EditForm from './EditForm'
import EditInspect from './EditInspect'
import EditDetail from './EditDetail'
const columns = [
  { title: '工序名称', dataIndex: 'Name', width: '10%' },
  { title: '工序编号', dataIndex: 'SN', width: '10%' },
  { title: '工时(h)', dataIndex: 'Duration', width: '10%' },
  { title: '定额', dataIndex: 'Money', width: '10%' },
  { title: '备注', dataIndex: 'Remarks', width: '10%' },
  { title: '技术要求', dataIndex: 'Require', width: '10%' },
  { title: '类型', dataIndex: 'ProcessType',  scopedSlots: { customRender: 'processType' },width: '10%'  },
  { title: '操作', dataIndex: 'action', scopedSlots: { customRender: 'action' } },
]

export default {
  components: {
    EditForm,
    EditInspect,
    EditDetail,
  },
  mounted() {
    this.getDataList()
  },
  data() {
    return {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showTotal: (total, range) => `总数:${total} 当前:${range[0]}-${range[1]}`,
      },
      filters: {},
      sorter: { field: 'Id', order: 'desc' },
      loading: false,
      columns,
      queryParam: {},
      selectedRowKeys: [],
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.pagination = { ...pagination }
      this.filters = { ...filters }
      this.sorter = { ...sorter }
      this.getDataList()
    },
    getDataList() {
      this.selectedRowKeys = []

      this.loading = true
      this.$http
        .post('/Main/Process/GetDataList', {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || 'Id',
          SortType: this.sorter.order,
          Search: this.queryParam,
          ...this.filters,
        })
        .then(resJson => {
          this.loading = false
          this.data = resJson.Data
          const pagination = { ...this.pagination }
          pagination.total = resJson.Total
          if (resJson.Data.length == 0 && resJson.Total > 0 && pagination.current > 1) {
            pagination.current = Math.ceil(resJson.Total / pagination.pageSize)
            this.pagination = pagination
            this.getDataList()
          }
          this.pagination = pagination
        })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
    hanldleAdd() {
      this.$refs.editForm.openForm()
    },
    handleEdit(id) {
      this.$refs.editForm.openForm(id)
    },
    handleCopy(id) {
      var thisObj = this
      this.$confirm({
        title: '确认复制吗?',
        onOk() {
          return new Promise(resolve => {
            thisObj.$http.post('/Main/Process/CopyProcess', { id: id }).then(resJson => {
              resolve()

              if (resJson.Success) {
                thisObj.$message.success('操作成功!')

                thisObj.getDataList()
              } else {
                thisObj.$message.error(resJson.Msg)
              }
            })
          })
        },
      })
    },
    handleEditInspect(id) {
      this.$refs.editInspect.openForm(id)
    },
    handleEditDetail(id) {
      this.$refs.editDetail.openForm(id)
    },
    handleDelete(ids) {
      var thisObj = this
      this.$confirm({
        title: '确认删除吗?',
        onOk() {
          return new Promise(resolve => {
            thisObj.$http.post('/Main/Process/DeleteData', ids).then(resJson => {
              resolve()

              if (resJson.Success) {
                thisObj.$message.success('操作成功!')

                thisObj.getDataList()
              } else {
                thisObj.$message.error(resJson.Msg)
              }
            })
          })
        },
      })
    },
  },
}
</script>
