var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":_vm.title,"width":"90%","visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":_vm.handleSubmit,"cancel":function () {
      this$1.visible = false
    }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}}),_c('vxe-button',{staticClass:"mb-2",on:{"click":function($event){return _vm.handleAdd(1)}}},[_vm._v("添加")]),_c('vxe-button',{staticClass:"mb-2",on:{"click":function($event){return _vm.handleAdd(5)}}},[_vm._v("添加5行")]),_c('vxe-button',{staticClass:"mb-2",on:{"click":function($event){return _vm.handleAdd(10)}}},[_vm._v("添加10行")]),_c('vxe-button',{staticClass:"mb-2",on:{"click":function($event){return _vm.handleAdd(20)}}},[_vm._v("添加20行")]),_c('vxe-table',{attrs:{"border":"","resizable":"","data":_vm.tableData,"edit-config":{ trigger: 'click', mode: 'cell' }}},[_c('vxe-table-column',{attrs:{"type":"seq","width":"60"}}),_c('vxe-table-column',{attrs:{"field":"Name","title":"名称","edit-render":{ name: 'input', immediate: true, attrs: { type: 'text' } }}}),_c('vxe-table-column',{attrs:{"field":"Number","title":"数量","edit-render":{ name: 'input', immediate: true, attrs: { type: 'text' } }}}),_c('vxe-table-column',{attrs:{"field":"Remark","title":"备注","edit-render":{ name: 'input', immediate: true, attrs: { type: 'text' } }}}),_c('vxe-table-column',{attrs:{"field":"Purpose","title":"用途","edit-render":{ name: 'input', immediate: true, attrs: { type: 'text' } }}}),_c('vxe-table-column',{attrs:{"title":"型号与品牌","width":"284"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('a-button',{staticClass:"mb-2",attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.handleBrandAdd(row.Brands)}}},[_vm._v("增加")]),_vm._l((row.Brands),function(brand,i){return _c('div',{key:brand._Id,staticClass:"d-flex ai-center jc-center mb-2"},[_c('div',[_c('vxe-input',{staticStyle:{"width":"100px"},attrs:{"placeholder":"型号","size":"medium"},model:{value:(brand.BrandModel),callback:function ($$v) {_vm.$set(brand, "BrandModel", $$v)},expression:"brand.BrandModel"}})],1),_c('a-divider',{attrs:{"type":"vertical"}}),_c('div',[_c('vxe-input',{staticStyle:{"width":"100px"},attrs:{"placeholder":"品牌","size":"medium"},model:{value:(brand.Brand),callback:function ($$v) {_vm.$set(brand, "Brand", $$v)},expression:"brand.Brand"}})],1),_c('a-button',{attrs:{"type":"link","size":"small"},on:{"click":function($event){return _vm.handleBrandDelete(row.Brands, i)}}},[_vm._v("删除")])],1)})]}}])}),_c('vxe-table-column',{attrs:{"title":"操作","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var rowIndex = ref.rowIndex;
return [_c('vxe-button',{attrs:{"status":"danger"},on:{"click":function($event){return _vm.handleDelete(rowIndex)}}},[_vm._v("删除")]),_c('vxe-button',{on:{"click":function($event){return _vm.handlesplice(rowIndex)}}},[_vm._v("插入")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }